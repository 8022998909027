<template>
  <v-card class="pa-3">
    <AuxInput title="Tipo">
      <v-select
        class="mt-2"
        :items="tipos"
        item-text="nombreTipo"
        item-value="idTipo"
        filled
        hide-details="auto"
        v-model="cont.idTipo"
      >
      </v-select>
    </AuxInput>
    <v-divider></v-divider>

    <h5 class="pa-4">Fecha y hora</h5>
    <div class="d-flex flex-wrap justify-center">
      <v-date-picker landscape v-model="cont.fecha" :max="max"></v-date-picker>
      <v-time-picker
        landscape
        format="24hr"
        v-model="cont.hora"
      ></v-time-picker>
    </div>
    <v-subheader>
      {{ parseDate(nuevaHora, false, true, true) }}
    </v-subheader>
    <v-divider />

    <AuxInput title="Respondida">
      <v-radio-group v-model="cont.respondida" row>
        <v-radio label="Sí" :value="true"></v-radio>
        <v-radio label="No" :value="false"></v-radio>
        <v-radio label="-" :value="null"></v-radio>
      </v-radio-group>
    </AuxInput>
    <v-divider></v-divider>

    <AuxInput title="Resumen">
      <v-textarea
        class="mt-4"
        v-model="cont.resumen"
        filled
        hide-details="auto"
      ></v-textarea>
    </AuxInput>
    <v-divider></v-divider>

    <v-card-actions class="mt-4">
      <v-spacer></v-spacer>

      <v-btn @click.stop="guardar" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  props: {
    contacto: Object,
    idEstudio: String | Number,
  },
  data() {
    return {
      cont: { ...this.contacto },
      tipos: [],
      max: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    nuevaHora() {
      if (!this.cont.hora || !this.cont.fecha) return;

      const [h, m] = this.cont.hora.split(":");
      let date = new Date(this.cont.fecha);
      date.setHours(h, m);
      return date.getTime();
    },
    textoResp() {
      switch (this.cont.respondida) {
        case null:
        case undefined:
          return "-";
        case true:
          return "Sí";
        case false:
          return "No";
        default:
          return "-";
      }
    },
  },
  methods: {
    parseDate,
    async getTipos() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/tipos_contacto`,
        });
        this.tipos = data;
      } catch (e) {
        console.log(e);
        this.$root.$emit("snack", "Ha ocurrido un error");
      }
    },
    guardar() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/contactos_estudio/${this.idEstudio}`,
        method: "POST",
        data: {
          idTipo: this.cont.idTipo,
          resumen: this.cont.resumen,
          fechaContacto: this.nuevaHora,
          respondida: this.cont.respondida,
        },
      }).then((res) => {
        this.$emit("close");
        this.$emit("reload");
        this.cont = {};
      });
    },
  },
  mounted() {
    this.getTipos();
  },
};
</script>

<style>
.active-tipo {
  border-color: var(--v-primary-base) !important;
  color: var(--v-primary-base) !important;
}
.tipo-icon {
  position: absolute !important;
  top: 5px;
  left: 5px;
}
</style>
